import videojs from "video.js";
$(document).ready(function () {
    let player = null;

    if ($('#fullpage-video-container').is(':visible')) {
        player = videojs('fullpage-video');
    } else {
        player = videojs('mobile-video');
    }

    player.autoplay('muted');
    player.loop(true);
});

